import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import RecipePreview from "../components/recipe-preview"
import NewsletterCta from "../components/newsletter-cta"
import Typography from "@material-ui/core/Typography"
import Grid from "@material-ui/core/Grid"
import Container from "@material-ui/core/Container"
import Button from "@material-ui/core/Button"
import Divider from "@material-ui/core/Divider"
import Box from "@material-ui/core/Box"
import ArrowForwardIcon from "@material-ui/icons/ArrowForward"

const IndexPage = ({ data }) => {
  const recipes = data.latestRecipes.edges
  const latestCakeRecipes = data.latestCakeRecipes.edges
  const latestPastryRecipes = data.latestPastryRecipes.edges
  const insights = data.latestInsights.edges

  return (
    <Layout>
      <Seo title="Home" />
      <Container maxWidth="lg">
        <Typography variant="h2" gutterBottom>
          Cake and Entremet Recipes
        </Typography>
        <Grid container spacing={1}>
          {latestCakeRecipes.map(({ node }, index) => {
            return (
              <Grid key={node.id} item xs={12} md={6} lg={4} zeroMinWidth>
                <RecipePreview recipe={node} eagerLoad={index < 2} />
              </Grid>
            )
          })}
        </Grid>
        <Box mt={6} mb={6}>
          <Divider />
        </Box>
      </Container>
      <Container maxWidth="lg">
        <Typography variant="h2" gutterBottom>
          Pastry Recipes
        </Typography>
        <Grid container spacing={1}>
          {latestPastryRecipes.map(({ node }, index) => {
            return (
              <Grid key={node.id} item xs={12} md={6} lg={4} zeroMinWidth>
                <RecipePreview recipe={node} eagerLoad={index < 2} />
              </Grid>
            )
          })}
        </Grid>
        <Box mt={6} mb={6}>
          <Divider />
        </Box>
      </Container>
      <Container maxWidth="lg">
        <Typography variant="h2" gutterBottom>
          Latest Recipes
        </Typography>
        <Grid container spacing={1}>
          {recipes.map(({ node }, index) => {
            return (
              <Grid key={node.id} item xs={12} md={6} lg={4} zeroMinWidth>
                <RecipePreview recipe={node} eagerLoad={index < 2} />
              </Grid>
            )
          })}
        </Grid>
        <Box mt={2} mb={2}>
          <Grid container>
            <Grid item xs={false} lg={9}></Grid>
            <Grid item xs={12} lg={3}>
              <Button
                href="/recipes"
                variant="contained"
                color="secondary"
                size="large"
                endIcon={<ArrowForwardIcon />}
                fullWidth
              >
                See more recipes
              </Button>
            </Grid>
          </Grid>
        </Box>
        <Box mt={6} mb={6}>
          <Divider />
        </Box>
      </Container>
      <Container maxWidth="lg">
        <Typography variant="h2" gutterBottom>
          Latest Insights
        </Typography>
        <Grid container spacing={1}>
          {insights.map(({ node }) => {
            return (
              <Grid key={node.id} item xs={12} md={6} lg={4} zeroMinWidth>
                <RecipePreview
                  recipe={node}
                  showAbstract={false}
                  path="insight"
                />
              </Grid>
            )
          })}
        </Grid>
        <Box mt={2} mb={2}>
          <Grid container>
            <Grid item xs={false} lg={9}></Grid>
            <Grid item xs={12} lg={3}>
              <Button
                href="/insights"
                variant="contained"
                color="secondary"
                size="large"
                endIcon={<ArrowForwardIcon />}
                fullWidth
              >
                See more insights
              </Button>
            </Grid>
          </Grid>
        </Box>
        <Box mt={6} mb={6}>
          <Divider />
        </Box>
      </Container>
      <Container maxWidth="md">
        <NewsletterCta />
      </Container>
    </Layout>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query HomeQuery {
    latestCakeRecipes: allContentfulRecipe(
      limit: 3
      sort: { fields: [createdAt], order: DESC }
      filter: { title: { regex: "/(Entremet|Torte|Cake)$/" } }
    ) {
      edges {
        node {
          id
          title
          slug
          createdAt(formatString: "MMMM Do, YYYY")
          abstract {
            abstract
          }
          thumbnailImage {
            title
            gatsbyImageData
          }
        }
      }
    }
    latestPastryRecipes: allContentfulRecipe(
      limit: 3
      sort: { fields: [createdAt], order: DESC }
      filter: { title: { regex: "/(Eclairs|Choux|Tart)$/" } }
    ) {
      edges {
        node {
          id
          title
          slug
          createdAt(formatString: "MMMM Do, YYYY")
          abstract {
            abstract
          }
          thumbnailImage {
            title
            gatsbyImageData
          }
        }
      }
    }
    latestRecipes: allContentfulRecipe(
      limit: 3
      sort: { fields: [createdAt], order: DESC }
    ) {
      edges {
        node {
          id
          title
          slug
          createdAt(formatString: "MMMM Do, YYYY")
          abstract {
            abstract
          }
          thumbnailImage {
            title
            gatsbyImageData
          }
        }
      }
    }
    latestInsights: allContentfulInsight(
      limit: 3
      sort: { fields: [createdAt], order: DESC }
    ) {
      edges {
        node {
          id
          title
          slug
          createdAt(formatString: "MMMM Do, YYYY")
          thumbnailImage {
            title
            gatsbyImageData
          }
        }
      }
    }
  }
`
